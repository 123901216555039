import React from 'react';
import ReactDOM, {createRoot} from 'react-dom/client';
import './index.css';
import Main, {PrivacyPolicy} from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route index element={<Main />} />
          <Route path="privacy" element={<PrivacyPolicy />} />  
          <Route path="privacy-policy" element={<PrivacyPolicy />} />  
          <Route path="*" element={<Main />} />
        </Routes>
      </BrowserRouter>
  );
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);