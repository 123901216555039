import icon from "./photos/icon.png";
import splash2 from "./photos/splash2.png";
import appleDownload from "./photos/apple-download.png";
import playDownload from "./photos/google-download.png";
import {FaClipboardList, FaRegLightbulb, FaMedal, FaShare, FaShieldAlt, FaDownload} from 'react-icons/fa';
import home from "./photos/mockup/home.png";
import assessment from "./photos/mockup/assessment.png";
import hazards from "./photos/mockup/hazards.png";
import products from "./photos/mockup/products.png";

import './App.css'
 
function Main() {
    return (
    <div className="bg-es-purple min-h-screen min-w-screen font-serif">
        <div className="flex flex-row justify-center text-white font-serif p-6 md:justify-start">
          <a href="https://eldersafe.app" className="flex flex-row">
            <img src={icon} alt="ElderSafe Icon" className="w-16 max-w-xl" />
            <h1 className="text-4xl text-white mx-6 self-center">ElderSafe</h1>
          </a>
        </div>

        <div id="splash" className="flex flex-col justify-evenly md:flex-row">
          <div className="flex flex-col self-center max-w-sm ">
            <h2 className="text-6xl text-white text-center m-3 md:text-left">Enhancing senior safety <span className="font-bold">at home.</span></h2>

            <div className="flex p-3 justify-center mb-4 md:mb-0 md:justify-start">
              <a href='https://play.google.com/store/apps/details?id=com.bobbrown.eldersafe' target="_blank" className="h-3 self-center md:self-start">
                <img alt='Get it on Google Play' src={playDownload} className=" max-h-10 pr-2 w-auto md:max-h-12"/>
              </a>
              <a href="https://apps.apple.com/us/app/eldersafe-senior-home-safety/id6477715134" target="_blank" className="h-3 self-center md:self-start">
                <img alt='Download on the App Store' src={appleDownload} className=" max-h-10 pl-2 w-auto md:max-h-12"/>
              </a>
            </div>
          </div>
          <img src={splash2} alt="ElderSafe Splash" className=" w-full self-end md:w-1/2" />
        </div>
        <div id="features" className="bg-es-purple-dark flex flex-col justify-evenly pt-8">
          <h1 className="self-center text-white text-4xl">Why ElderSafe?</h1>
          <br />
          <p className="self-center text-white text-xl max-w-4xl text-center mx-7"><strong>33%</strong> of seniors need to make home modifications to live safely and independently. ElderSafe helps identify and prioritize home safety modifications.</p>
          <div className="flex flex-row flex-wrap justify-evenly align-top my-8">
            <div className="flex flex-col max-w-sm m-3 text-center">
              <div className="bg-es-purple p-3 rounded-full self-center">
                <FaClipboardList color="white" size="30"/>
              </div>
              <h3 className="text-2xl text-white m-3">Specific Safety Assessments</h3>
              <p className="text-xl text-white m-3">Take a proactive approach to home safety with room-specific evaluations.</p>
            </div>
            <div className="flex flex-col max-w-sm m-3 text-center">
              <div className="bg-es-purple p-3 rounded-full self-center">
                <FaRegLightbulb color="white" size="30"/>
              </div>
              <h3 className="text-2xl text-white m-3">Personalized Recommendations</h3>
              <p className="text-xl text-white m-3">Receive personalized suggestions on safety products tailored to your needs.</p>
            </div>
            <div className="flex flex-col max-w-sm m-3 text-center">
              <div className="bg-es-purple p-3 rounded-full self-center">
                <FaMedal color="white" size="30"/>
              </div>
              <h3 className="text-2xl text-white m-3">Dynamic Home Safety Score</h3>
              <p className="text-xl text-white m-3">Get an instantly calculated home safety score and track improvements over time.</p>
            </div>

            <div className="max-w-7xl px-8 pt-8 bg-es-purple rounded-xl m-4">
              <h2 className="text-4xl text-white text-center mb-2">Quick. Easy. Comprehensive.</h2>
              <div className="flex flex-row flex-wrap justify-evenly align-top">
                <img src={home} alt="Home Screen" className="w-0 self-center md:w-1/6" />     
                <img src={assessment} alt="Assessment Screen" className="w-0 self-center md:w-1/6" />       
                <img src={hazards} alt="Hazards Screen" className="w-1/2 self-center md:w-1/6" />
                <img src={products} alt="Products Screen" className="w-1/2 self-center md:w-1/6" />
              </div>
            </div>
          </div>
        </div>
        
        <div id="importance" className="flex flex-col justify-evenly p-8">
          <h1 className="self-center text-white text-center text-4xl">Senior Injuries in America</h1>
          <div className="flex flex-row flex-wrap justify-evenly align-top my-8">
            <div className="flex flex-col max-w-sm m-3 text-center bg-es-purple-dark rounded-2xl p-4 transform transition duration-250 hover:scale-105">
              <h3 className="text-5xl text-white m-3 font-bold">1 in 4</h3>
              <p className="text-xl text-white m-3">Seniors fall in the United States every year</p>
            </div>
            <div className="flex flex-col max-w-sm m-3 text-center bg-es-purple-dark rounded-2xl p-4 transform transition duration-250 hover:scale-105">
              <h3 className="text-5xl text-white m-3 font-bold">1 in 5</h3>
              <p className="text-xl text-white m-3">Senior falls result in a broken bone or head injury</p>
            </div>
            <div className="flex flex-col max-w-sm m-3 text-center bg-es-purple-dark rounded-2xl p-4 transform transition duration-250 hover:scale-105">
              <h3 className="text-5xl text-white m-3 font-bold">$50 B</h3>
              <p className="text-xl text-white m-3">Spent in medical bills every year due to senior falls</p>
            </div>
          </div>
          <p className="self-center text-white text-xl max-w-4xl text-center mx-7">Take the first step in protecting America's aging population by <a href="https://play.google.com/store/apps/details?id=com.bobbrown.eldersafe" target="_blank" className="underline">downloading ElderSafe</a> today!</p>
        </div>

        <div id="footer" className="flex flex-col justify-evenly p-8 bg-es-purple">
          <div className="flex flex-col md:flex-row justify-center items-center"> {/* Add justify-center class */}
            <a href="mailto:bob@bob-brown.com" target="_blank" className="flex flex-row text-white text-xl mx-6 text-center">
              <FaShare className="mr-3 mt-1" />
              Contact
            </a>
            <a className="flex flex-row text-white text-xl mx-6 text-center" target="_blank" href="https://play.google.com/store/apps/details?id=com.bobbrown.eldersafe">
              <FaDownload className="mr-3 mt-1" />
              Download
            </a>
            <a href="https://eldersafe.app/privacy" target="_blank" className="flex flex-row text-white text-xl mx-6 text-center">
              <FaShieldAlt className="mr-3 mt-1" />
              Privacy Policy
            </a>
          </div>
          <br />
          <p className="self-center text-white text-xl">© ElderSafe 2023 - 2024</p>
        </div>
      </div>
    )
}

function PrivacyPolicy() {
    return (
        <div className="bg-white min-h-screen min-w-screen p-4 font-serif">
            <strong>Privacy Policy</strong> <p>
        This app is completely free-to-use. This service is provided at no cost and is intended for use as is.
                </p> <p>
                  This page is used to inform visitors regarding my
                  policies with the collection, use, and disclosure of Personal
                  Information if anyone decided to use my Service.
                </p> <p>
                  If you choose to use my Service, then you agree to
                  the collection and use of information in relation to this
                  policy. The Personal Information that I collect is
                  used for providing and improving the Service. I will not use or share your information with
                  anyone except as described in this Privacy Policy.
                </p> <p>
                  The terms used in this Privacy Policy have the same meanings
                  as in our Terms and Conditions, which are accessible at
                  ElderSafe unless otherwise defined in this Privacy Policy.
                </p> <p><strong>Information Collection and Use</strong></p> <p>
                This app does collects app interaction data, such as pages visited and buttons pressed. However, any information you enter into the app is stored locally on your device and is not sent to any external servers.
                </p> <div><p>
                    The app does use third-party services that may collect
                    information used to identify you.
                  </p>
                <p><strong>Cookies</strong></p> <p>
                  Cookies are files with a small amount of data that are
                  commonly used as anonymous unique identifiers. These are sent
                  to your browser from the websites that you visit and are
                  stored on your device's internal memory.
                </p> <p>
                  This service does not use these “cookies” explicitly. However,
                  the app may use third-party code and libraries that use
                  “cookies” to collect information and improve their services.
                  You have the option to either accept or refuse these cookies
                  and know when a cookie is being sent to your device. If you
                  choose to refuse our cookies, you may not be able to use some
                  portions of this Service.
                </p> <p><strong>Service Providers</strong></p> <p>
                  I may employ third-party companies and
                  individuals due to the following reasons:
                </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
                  I want to inform users of this Service
                  that these third parties have access to their Personal
                  Information. The reason is to perform the tasks assigned to
                  them on our behalf. However, they are obligated not to
                  disclose or use the information for any other purpose.
                </p> <p><strong>Security</strong></p> <p>
                  I value your trust in providing us your
                  Personal Information, thus we are striving to use commercially
                  acceptable means of protecting it. But remember that no method
                  of transmission over the internet, or method of electronic
                  storage is 100% secure and reliable, and I cannot
                  guarantee its absolute security.
                </p> <p><strong>Links to Other Sites</strong></p> <p>
                  This service may contain links to other sites. If you click on
                  a third-party link, you will be directed to that site. Note
                  that these external sites are not operated by me.
                  Therefore, I strongly advise you to review the
                  Privacy Policy of these websites. I have
                  no control over and assume no responsibility for the content,
                  privacy policies, or practices of any third-party sites or
                  services.
                </p> <p><strong>Children's Privacy</strong></p> <div><p>
                    These Services do not address anyone under the age of 13.
                    I do not knowingly collect personally
                    identifiable information from children under 13 years of age. In the case
                    I discover that a child under 13 has provided
                    me with personal information, I immediately
                    delete this from our servers. If you are a parent or guardian
                    and you are aware that your child has provided us with
                    personal information, please contact me so that
                    I will be able to do the necessary actions.
                  </p></div><p><strong>Changes to This Privacy Policy</strong></p> <p>
                  I may update our Privacy Policy from
                  time to time. Thus, you are advised to review this page
                  periodically for any changes. I will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p> <p>This policy is effective as of 2023-09-25</p> <p><strong>Contact Us</strong></p> <p>
                  If you have any questions or suggestions about my
                  Privacy Policy, do not hesitate to contact me at bob@bob-brown.com.</p>
                </div>    
        </div>
    )
}

export default Main;
export {PrivacyPolicy};